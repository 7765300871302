import React from "react";

const Cancellation = () => {
  return (
    <section className="aboutsection">
      <div className="aboutcontainer-div">
        <h1>PRODUCT CANCELLATION POLICY</h1>
        <p>
          You have a statutory right to change your mind (without giving a
          reason) within 24 hours of joining a training/course and receive a
          refund. This will not apply if you have already started attending
          training and downloading the relevant product. Fees paid once shall
          not be refunded if claimed after 24 hours, but training can be
          availed.
        </p>
        <p>
          If you want to end your course or training with us, please let us know
          by doing one of the following: Email us at
          businessguruoffice@gmail.com Please provide your name, home address,
          details of the order and, where available, your phone number and email
          address. telling us of the reason you wish to cancel.
        </p>

        <a href="https://wa.me/918637608759" target="_blank" rel="noreferrer">
          <button>FIND OUT MORE</button>
        </a>
      </div>
    </section>
  );
};

export default Cancellation;
