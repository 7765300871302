import React from "react";
import Header from "../Components/nav";
import Footer from "../Components/footer";
import Form from "../Components/form";
// import Servicetemplate from "../Components/servicetemplate";
import Leadgen from "../Components/leadgen";
import Bottomfooter from "../Components/bottomfooter";

const ServiceRoute5 = () => {
  return (
    <div>
      <Header />
      <Form
        serviceName="Lead Generation"
        apiRoute="https://business-guru-server.vercel.app/lead-generation"
      />
      <Leadgen />
      <Footer />
      <Bottomfooter />
    </div>
  );
};

export default ServiceRoute5;
