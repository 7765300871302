import React from "react";
import Header from "../Components/nav";
import Footer from "../Components/footer";
import Form from "../Components/form";
import Webdev from "../Components/webdev";
import Bottomfooter from "../Components/bottomfooter";

const ServiceRoute7 = () => {
  return (
    <div>
      <Header />
      <Form
        serviceName="Web Development"
        apiRoute="https://business-guru-server.vercel.app/web-development"
      />
      <Webdev />

      <Footer />
      <Bottomfooter />
    </div>
  );
};

export default ServiceRoute7;
