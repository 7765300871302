import "./privacy.css";

const Privacy = () => {
  return (
    <section className="aboutsection">
      <div className="aboutcontainer-div">
        <h1>PRIVACY POLICY</h1>

        <div>
          <h2>Privacy Policy</h2>
          <p>
            This privacy policy sets out how Business Guru Website uses and
            protects any information that you give when you use this website.
          </p>
          <p>
            Business Guru Website are committed to ensuring that your privacy is
            protected. Should we ask you to provide certain information by which
            you can be identified when using this website then you can be
            assured that it will only be used in accordance of the terms set out
            within this privacy policy.
          </p>

          <p>
            We reserve the right to change this policy by updating this page.
            You should check this page from time to time to ensure that you are
            happy with any changes.
          </p>

          <h2>What information do we collect?</h2>
          <p>We may collect the following information:</p>
          <ul>
            <li>Your name</li>
            <li>Your Whatsapp number</li>
            <li>Your website address</li>
            <li>
              Your contact information including email address and phone number
            </li>
            <li>
              Demographic information such as postcode, preferences and
              interests
            </li>
            <li>
              Other information relevant to customer surveys and/or offers
            </li>
          </ul>

          <h2>How we use this information do we collect?</h2>
          <p>
            We require this information to understand your needs and provide you
            with a better service, and in particular for the following reasons:
          </p>
          <ul>
            <li>Internal record keeping.</li>
            <li>Improving our products and services.</li>
            <li>
              We may periodically contact you by phone, email, text message or
              mail to inform you about new products, special offers or other
              information which we think you may find interesting using the
              information you have provided.
            </li>
            <li>
              From time to time, we may also use your information to contact you
              for market research purposes.
            </li>
            <li>
              We may use the information to customise the website according to
              your interests.
            </li>
          </ul>

          <h2>Security</h2>
          <p>
            We are committed to ensuring that your information is secure. In
            order to prevent unauthorised access or disclosure, we have put in
            place suitable physical, electronic and managerial procedures to
            safeguard and secure the information we collect online.
          </p>

          <h2>Links to other websites</h2>
          <p>
            Our website may contain links to other websites of interest. This
            privacy policy only covers use of www.business-guru.in and therefore
            we cannot be responsible for the protection and privacy of any
            information you provide when you visit sites we link to. We advise
            when visiting other websites you exercise caution and check the
            privacy statement applicable to the website in question.
          </p>

          <h2>Controlling your personal informationn</h2>
          <p>
            You may choose to restrict the collection or use of your personal
            information in the following ways:
          </p>
          <ul>
            <li>
              Whenever you receive an email or text message we will provide you
              with an “unsubscribe” link. Click on the link and follow further
              instructions to remove yourself from future communications.
            </li>
            <li>
              Whenever you receive a phone call from us you may request that we
              do not contact you by phone in future.
            </li>
            <li>
              If you have previously agreed to us using your personal
              information for direct marketing purposes, you may change your
              mind at any time by writing to or emailing us at
              businessguruoffice@gmail.com
            </li>
          </ul>

          <p>
            Business Guru Website customers should note we reserve the right to
            contact you with important account information deemed necessary to
            ensure you receive an effective service. This includes the
            communication of results and other information deemed by Business
            Guru Website to be relevant and important in ensuring you receive
            the best possible service and results. Business Guru Website
            distinguishes between important account information and
            communications intended to keep you informed generally about Add
            People promotions, discounts, products and services which you will
            receive unless you inform us you do not want to.
          </p>
          <p>
            Business Guru Website will not sell, distribute or lease your
            personal information to third parties unless we have your permission
            or are required by law to do so. We may use your personal
            information to send you promotional information about Business Guru
            Website services.
          </p>
          <p>
            If you believe that any information we are holding on you is
            incorrect or incomplete, please email us as soon as possible at
            businessguruoffice@gmail.com and we will correct it in line with
            your request.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Privacy;
