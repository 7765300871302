import "./terms.css";

const Terms = () => {
  return (
    <section className="aboutsection">
      <div className="aboutcontainer-div">
        <h1>TERMS AND CONDITIONS</h1>

        <div>
          <h2>Terms and Conditions of Business</h2>
          <p>
            These Website Terms and Conditions of Business govern your use of
            our online trainings by www.business-guru.in, (the “Website”) and
            your relationship with Business Guru (“we”, “us”). Please read these
            terms carefully as they affect your rights and liabilities under the
            law. If you do not agree to these Terms of Use, please do not
            register with us for Trainings, use the Website, or purchase any of
            our Products. Please also see our Privacy Policy for information
            about how we collect and use your personal data. Business Guru (nor
            any of its employees, agents or representatives), is engaged in
            rendering training advice, nor does it (or any of its employees,
            agents or representatives) hold itself of out as qualified to do so.
            We strongly recommend that you seek that you seek professional
            training advice before embarking on any construction activities.
          </p>

          <h2>1.Introduction</h2>
          <ul>
            <li>
              This Website provides an online personal training service through
              which you can undergo various structural engineering design
              practices.
            </li>
            <li>
              These terms will apply to all users (“you”) of the Website and all
              purchasers of Products.
            </li>
            <li>
              By using the Website, or by purchasing any Products from us, you
              agree to be bound by these Terms of Use.
            </li>
            <li>
              Please note that these Terms of Use may be amended from time to
              time. Notification of any changes will be made by us posting new
              terms onto the Website. In continuing to use the Website you
              confirm that you accept the then current Terms of Use in full at
              the time you use the Website. If you do not accept the amended
              Terms of Use then you must cancel your registration or
              subscription and the previous terms will continue to apply pending
              termination.
            </li>
          </ul>

          <h2>2.Our Products</h2>
          <p>
            We will offer through the Website some of the following products
            (the “Products”)
          </p>
          <ul>
            <li>e-books;</li>
            <li>videos;</li>
            <li>training guides;</li>
            <li>online coaching;</li>
          </ul>

          <h2>3.Fees</h2>
          <ul>
            <li>
              You can view and access the Website free of charge, however in
              order to purchaser any of our Products you must contact the
              concerned mentor of this website.
            </li>
            <li>
              The fees payable in respect of the products and services will be
              clearly displayed on the Website.
            </li>
          </ul>

          <h2>4.How to contact us</h2>
          <ul>
            <li>
              BusinessGuru D-54, 2nd Floor, Trichy Coworks, 9th A Cross, Thillai
              Nagar, Tiruchirappalli, Tamil Nadu 620018.
            </li>
          </ul>

          <h2>5.Registration</h2>
          <ul>
            <li>
              When you register as a trainee we will ask that you provide
              certain personal information including but not limited to your
              name, email address, postal address, and, your payment details.
              Any personal information you provide to us with will be handled in
              accordance with our Privacy policy.
            </li>
            <li>
              You agree that all personal information that you supply to us will
              be accurate, complete and kept up to date at all times. We may use
              the information provided to us to contact you.
            </li>
          </ul>

          <h2>6.Products: Cancellation</h2>
          <p>
            You have a statutory right to change your mind (without giving a
            reason) within 10 days of joining a course and receive a refund.
            This will not apply if you have already started attending training
            and downloading the relevant product. Feed paid once shall not be
            refunded if claimed after 10 days, but training can be availed.
          </p>
          <ul>
            <p>
              If you want to end your course or training with us, please let us
              know by doing one of the following:
            </p>
            <li>
              Email us at businessguruoffice@gmail.com Please provide your name,
              home address, details of the order and, where available, your
              phone number and email address. telling us of the reason you wish
              to cancel.
            </li>
          </ul>

          <h2>7. Availability</h2>
          <ul>
            <li>
              Although we aim to offer you the best service possible, we make no
              promise that the training will meet your requirements. We cannot
              guarantee that the training will be fault-free. If a fault occurs
              with the Website you should report it to
              businessguruoffice@gmail.com and we will attempt to correct the
              fault as soon as we reasonably can.
            </li>
            <li>
              We will be available to attend the comments and requests on Monday
              to Saturday 11am to 10pm. Sunday is a holiday.
            </li>
          </ul>

          <h2>8. General</h2>
          <ul>
            <li>
              These conditions are governed by and construed in accordance with
              the laws of India. You agree, as we do, to submit to the
              non-exclusive jurisdiction of the Indian courts.
            </li>
            <li>
              If you breach these Terms of Use and we decide to take no action
              or neglect to do so, then we will still be entitled to take action
              and enforce our rights and remedies for any other breach.
            </li>
            <li>
              We will not be responsible for any breach of these Terms of Use
              caused by circumstances beyond our reasonable control.
            </li>
            <li>
              We may make changes to the format of the Trainings at any time
              without notice.
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Terms;
